import { gql } from '@apollo/client';

export const CREATE_CARD = gql`
  mutation CreateCreditCard(
    $displayName: String
    $nameOnCard: String!
    $virtual: Boolean!
    $address: AddressInput
    $pin: String
    $contactId: ID!
  ) {
    createCreditCard(
      displayName: $displayName
      nameOnCard: $nameOnCard
      virtual: $virtual
      address: $address
      pin: $pin
      contactId: $contactId
    ) {
      id
      displayName
      lastFourDigits
      virtual
      status
      creditLimitCents
      creditLimitCurrency
      paymentDate
      amountSpent {
        amount
        currency
      }
      amountDue {
        amount
        currency
      }
      groupBalances {
        amount
        currency
      }
      groupPendingBalances {
        amount
        currency
      }
      groupPostedBalances {
        amount
        currency
      }
      groupAvailableBalance {
        amount
        currency
      }
      contacts {
        id
        name
        role
      }
      processorSettings {
        allowedMccsCategories
        blockedMccsCategories
        allowedCurrencies
        blockedCurrencies
        merchantsList
        allowedMerchants
        limitSettings {
          cadence
          amount {
            amount
            currency
          }
          spentAmount {
            amount
            currency
          }
          nextCadenceStartsAt
        }
      }
    }
  }
`;

export const CREATE_CREDIT_CARD = gql`
  mutation CreateCreditCard(
    $displayName: String
    $nameOnCard: String!
    $virtual: Boolean!
    $address: AddressInput
    $pin: String
    $contactId: ID!
    $cardProductType: String
  ) {
    createCreditCard(
      displayName: $displayName
      nameOnCard: $nameOnCard
      virtual: $virtual
      address: $address
      pin: $pin
      contactId: $contactId
      cardProductType: $cardProductType
    ) {
      id
      cardProcessorId
      displayName
      lastFourDigits
      virtual
      status
      contacts {
        id
        firstName
        lastName
        name
        role
      }
      processorSettings {
        allowedMccsCategories
        blockedMccsCategories
        allowedCurrencies
        blockedCurrencies
        merchantsList
        allowedMerchants
        limitSettings {
          cadence
          amount {
            amount
            currency
          }
          spentAmount {
            amount
            currency
          }
          nextCadenceStartsAt
        }
      }
    }
  }
`;

export const UPDATE_CARD_DISPLAY_NAME = gql`
  mutation UpdateCardDisplayName($creditCardId: ID!, $displayName: String!) {
    updateCardDisplayName(creditCardId: $creditCardId, displayName: $displayName)
  }
`;

export const GET_CREDIT_CARD_BY_ID = gql`
  query CreditCard($creditCardId: ID) {
    creditCard(creditCardId: $creditCardId) {
      id
      virtual
      status
      lastFourDigits
      encryptedCardNumber
      encryptedCvc2
      encryptedExpirationDate
      cardHolderName
      displayName
      encryptedPin
      trackerUrl
      address {
        street
        postalCode
        city
        country
        countrySubdivision
        category
        unitNumber
      }
      addresses {
        street
        postalCode
        city
        country
        countrySubdivision
        category
        unitNumber
      }
      recentTransactions {
        transactionId
        amount {
          amount
          currency
        }
        createdAt
        completedAt
        status
        type
        note
        from
        to
        fxExchangeRate
        cardTransaction
      }
      isCreator
      contacts {
        id
        name
        role
      }
    }
  }
`;

export const GET_CREDIT_CARDS_FOR_ACTIVATION = gql`
  query CreditCardsForActivation {
    me {
      ... on User {
        account {
          creditCards {
            id
            status
            settings
            virtual
          }
        }
      }
    }
  }
`;

export const GET_CREDIT_LIMIT = gql`
  query CreditLimit {
    me {
      ... on User {
        account {
          globalCreditLimit {
            amount {
              amount
              currency
            }
            creditCardDueDay
          }
          bankAccount {
            connected
            verified
          }
          kycAssessment {
            status
          }
          hasExpressApplication
          lineOfCredit {
            id
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNTS_FOR_PAYEE_PAYMENTS = gql`
  query AccountsForPayeePayments {
    me {
      ... on User {
        account {
          creditCard {
            id
            accounts {
              id
              currency
              balance {
                amount
                currency
              }
              displayName
            }
            groupAvailableBalance {
              amount
              currency
            }
          }
          wallets {
            id
            displayName
            currency
            balance {
              amount
              currency
            }
            availableBalance {
              amount
              currency
            }
            externalAccounts {
              ... on SolidAccount {
                internalId
                ready
              }
              ... on CurrencyCloudExternalAccount {
                internalId
                ready
              }
              ... on CoreExternalAccount {
                internalId
                ready
              }
            }
          }
          lineOfCredit {
            id
            currency
            ledgerAccount {
              id
              displayName
              currency
            }
            availableCredit {
              amount
              currency
            }
          }
          bankAccounts {
            id
            displayName
            currency
            preDepositAuthorization
            verified
            country
          }
        }
      }
    }
  }
`;

// -- start debug queries -- //
export const DEBUG_GET_LOC = gql`
  query DebugLoc {
    me {
      ... on User {
        account {
          lineOfCredit {
            id
            currency
            ledgerAccount {
              id
              displayName
              balance {
                amount
                currency
              }
              availableBalance {
                amount
                currency
              }
              pendingAmount {
                amount
                currency
              }
              currency
            }
            outstandingBalance {
              amount
              currency
            }
            currentBalance {
              amount
              currency
            }
            availableCredit {
              amount
              currency
            }
            creditLimit {
              amount
              currency
            }
            postedBalance {
              amount
              currency
            }
            pendingBalance {
              amount
              currency
            }
          }
        }
      }
    }
  }
`;

export const DEBUG_GET_BANK_ACCOUNTS = gql`
  query DebugBankAccounts {
    me {
      ... on User {
        account {
          bankAccounts {
            id
            displayName
            currency
            preDepositAuthorization
          }
        }
      }
    }
  }
`;

export const DEBUG_GET_WALLETS = gql`
  query DebugWallets {
    me {
      ... on User {
        account {
          wallets {
            id
            displayName
            currency
            balance {
              amount
              currency
            }
            availableBalance {
              amount
              currency
            }
            externalAccounts {
              ... on SolidAccount {
                internalId
                ready
              }
              ... on CurrencyCloudExternalAccount {
                internalId
                ready
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PAY_CARD_BALANCE = gql`
  query PayCardBalance {
    me {
      ... on User {
        account {
          creditCard {
            amountDue {
              amount
              currency
            }
            statementBalanceInCad {
              amount
              currency
            }
            statementBalanceInUsd {
              amount
              currency
            }
            eurStatementBalanceInCad {
              amount
              currency
            }
            gbpStatementBalanceInCad {
              amount
              currency
            }
            usdStatementBalanceInCad {
              amount
              currency
            }
          }
          wallets {
            id
            displayName
            currency
            balance {
              amount
              currency
            }
            availableBalance {
              amount
              currency
            }
          }
          bankAccounts {
            id
            displayName
            currency
            preDepositAuthorization
            institutionName
            maskedNumber
            institutionNumber
            transitNumber
            country
          }
        }
      }
    }
  }
`;

export const GET_CREDIT_CARD_STATEMENTS = gql`
  query CreditCardStatements {
    primaryCreditCard {
      id
      groupOngoingAmountDueByCurrency
      groupOngoingPayments {
        amount
        currency
      }
      latestCycleStartDate
      statements {
        id
        title
        createdAt
        startDate
        endDate
      }
    }
  }
`;

export const GET_CREDIT_CARD_STATEMENT = gql`
  query Statement($statementId: ID!) {
    statement(statementId: $statementId) {
      id
      credits
      debits
      dueDate
      previousStartDate
      previousEndDate
      previousBalanceByCurrency
      endingBalanceByCurrency
      payment {
        status
      }
    }
  }
`;

export const GET_CURRENT_BILLING_CYCLE_INFO = gql`
  query CurrentBillingCycleInfo {
    primaryCreditCard {
      id
      billingCycle {
        startDate
      }
      groupBalances {
        amount
        currency
      }
      latestStatement {
        id
        endingBalanceByCurrency
        dueDate
        startDate
        endDate
      }
      groupCurrentCycleSpentByCurrency
      groupCurrentCyclePaymentsByCurrency
      nextStatementGenerationDate
    }
  }
`;

export const UPDATE_CARD_STATUS = gql`
  mutation UpdateCardStatus($creditCardId: ID!, $status: CreditCardStatusEnum!) {
    updateCardStatus(creditCardId: $creditCardId, status: $status)
  }
`;

export const ACTIVATE_CREDIT_CARD = gql`
  mutation ActivateCreditCard($creditCardGid: String!, $cvc2: String!, $lastFourDigits: String!) {
    activateCreditCard(creditCardGid: $creditCardGid, cvc2: $cvc2, lastFourDigits: $lastFourDigits)
  }
`;

export const GET_CREDIT_CARDS_IDS = gql`
  query CreditCardsIds {
    me {
      ... on User {
        account {
          creditCards {
            id
          }
          kycAssessment {
            status
          }
          hasExpressApplication
        }
      }
    }
  }
`;

export const GET_CREDIT_CARDS_FOR_LIST = gql`
  query CreditCardsForList {
    me {
      ... on User {
        account {
          creditCards {
            id
            displayName
            nickname
            lastFourDigits
            virtual
            status
            creditLimitCents
            creditLimitCurrency
            nameOnCard
            contacts {
              id
              firstName
              lastName
              name
              role
            }
          }
        }
      }
    }
  }
`;

export const GET_CREDIT_CARDS_FOR_CARD_CREATE = gql`
  query CreditCardsIds {
    me {
      ... on User {
        account {
          creditCards {
            id
            displayName
            virtual
          }
        }
      }
    }
  }
`;

export const PAY_BALANCE_GET_GROUPED_CARD_INFO = gql`
  query PayBalanceGroupedCardInfo {
    primaryCreditCard {
      groupBalances {
        amount
        currency
      }
      groupOngoingAmountDueByCurrency
      groupOngoingMinimumAmountDueByCurrency
      groupConvertedMinimumAmountDueByCurrency
    }
  }
`;

export const GET_GROUPED_CARD_INFO = gql`
  query GroupedCardInfo {
    primaryCreditCard {
      cycle
      billingCycle {
        startDate
        endDate
      }
      paymentDate
      groupCreditLimit {
        amount
        currency
      }
      groupOngoingAmountDueByCurrency
      groupOngoingMinimumAmountDueByCurrency
      amountDue {
        amount
        currency
      }
      groupBalances {
        amount
        currency
      }
      groupPendingBalances {
        amount
        currency
      }
      groupPostedBalances {
        amount
        currency
      }
      groupAvailableBalance {
        amount
        currency
      }
      groupOverlimit {
        amount
        currency
      }
    }
  }
`;

export const GET_PRIMARY_CARD_LEDGER_ACCOUNTS = gql`
  query PrimaryCardLedgerAccounts {
    primaryCreditCard {
      id
      accounts {
        id
        currency
        displayName
      }
    }
  }
`;

export const ADD_CONTACT_TO_PRODUCT_PERMISSION = gql`
  mutation AddContactToProductPermission($contactId: ID!, $creditCardId: ID!) {
    addContactToProductPermission(contactId: $contactId, creditCardId: $creditCardId)
  }
`;

export const REMOVE_CONTACT_FROM_PRODUCT_PERMISSION = gql`
  mutation RemoveContactFromProductPermission($contactId: ID!, $creditCardId: ID!) {
    removeContactFromProductPermission(contactId: $contactId, creditCardId: $creditCardId)
  }
`;

export const GET_MEMBERS = gql`
  query Members {
    me {
      ... on User {
        account {
          internalContacts {
            id
            firstName
            lastName
            role
            status
            email
            positions
            name
            permissions
            rules {
              paymentApprovalAmount {
                amount
                currency
              }
            }
            user {
              lastActiveAt
            }
          }
        }
      }
    }
  }
`;

export const RESET_CARD_PIN_ATTEMPTS = gql`
  mutation ResetCardPinAttempts($creditCardId: ID!) {
    resetCardPinAttempts(creditCardId: $creditCardId)
  }
`;

export const UPDATE_CARD_ADDRESS = gql`
  mutation UpdateCardAddress($creditCardId: ID!, $address: AddressInput!) {
    updateCardAddress(creditCardId: $creditCardId, address: $address)
  }
`;

export const UPDATE_CARD_LIMIT_SETTINGS = gql`
  mutation UpdateCardLimitSettings(
    $creditCardId: ID!
    $limit: MoneyInput!
    $expiresMonthly: Boolean!
    $expiresAnnually: Boolean!
  ) {
    updateCardLimitSettings(
      creditCardId: $creditCardId
      limit: $limit
      expiresMonthly: $expiresMonthly
      expiresAnnually: $expiresAnnually
    )
  }
`;

export const GET_CARD_LIMIT_SETTINGS = gql`
  query CreditCardSettings($creditCardId: ID) {
    creditCard(creditCardId: $creditCardId) {
      id
      limitSettings {
        cardLimitCents
        expiresMonthly
        expiresAnnually
        currentExpenseCents
      }
    }
  }
`;

export const GET_CARDS_GLOBAL_LIMIT_INFO = gql`
  query CardsGlobalLimitInfo {
    primaryCreditCard {
      id
      groupAmountDue {
        amount
        currency
      }
      groupAvailableBalance {
        amount
        currency
      }
      groupCreditLimit {
        amount
        currency
      }
      groupOverlimit {
        amount
        currency
      }
      groupTotalSpent {
        amount
        currency
      }
    }
  }
`;

export const GET_CREDIT_CARD_BALANCES = gql`
  query CreditCardBalances {
    primaryCreditCard {
      id
      groupBalances {
        amount
        currency
      }
      groupPendingBalances {
        amount
        currency
      }
      groupPostedBalances {
        amount
        currency
      }
      billingCycle {
        startDate
        endDate
      }
    }
  }
`;

export const GET_PRE_FUNDED_CARD_BALANCES = gql`
  query PreFundedCardBalances {
    primaryCreditCard {
      id
      groupBalances {
        amount
        currency
      }
      groupOngoingPayments {
        amount
        currency
      }
    }
  }
`;

export const GET_CREDIT_CARDS_LIST = gql`
  query CreditCardsList {
    creditCards {
      id
      displayName
      lastFourDigits
      virtual
      status
      contacts {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_CREDIT_CARD_DETAILS_BY_ID = gql`
  query CreditCardDetailsById($creditCardId: ID) {
    creditCard(creditCardId: $creditCardId) {
      id
      cardProcessorId
      virtual
      status
      displayName
      lastFourDigits
      cardHolderName
      addresses {
        street
        postalCode
        city
        country
        countrySubdivision
        category
        unitNumber
      }
    }
  }
`;

export const GET_CREDIT_CARD_MEMBERS = gql`
  query CreditCardMembers($creditCardId: ID) {
    creditCard(creditCardId: $creditCardId) {
      id
      contacts {
        id
        name
        firstName
        lastName
        role
      }
    }
  }
`;

export const GET_CREDIT_CARD_PROCESSOR_SETTINGS = gql`
  query CreditCardProcessorSettings($creditCardId: ID) {
    creditCard(creditCardId: $creditCardId) {
      id
      processorSettings {
        allowedMccsCategories
        blockedMccsCategories
        allowedCurrencies
        blockedCurrencies
        merchantsList
        allowedMerchants
        limitSettings {
          cadence
          amount {
            amount
            currency
          }
          spentAmount {
            amount
            currency
          }
          nextCadenceStartsAt
        }
      }
    }
  }
`;

export const UPDATE_CREDIT_CARD_PROCESSOR_SETTINGS = gql`
  mutation UpdateCreditCardProcessorSettings(
    $creditCardId: ID!
    $processorSettings: CreditCardProcessorSettingsInput!
  ) {
    setCardProcessorSettings(creditCardId: $creditCardId, processorSettings: $processorSettings) {
      allowedMccsCategories
      blockedMccsCategories
      allowedCurrencies
      blockedCurrencies
      merchantsList
      allowedMerchants
      limitSettings {
        cadence
        amount {
          amount
          currency
        }
        spentAmount {
          amount
          currency
        }
        nextCadenceStartsAt
      }
    }
  }
`;

export const UPDATE_CREDIT_CARD_MEMBERS = gql`
  mutation UpdateCreditCardMembers($creditCardId: ID!, $contactIds: [ID!]) {
    updateContactsInProductPermission(creditCardId: $creditCardId, contactIds: $contactIds) {
      id
      contacts {
        id
        name
        firstName
        lastName
        role
      }
    }
  }
`;
