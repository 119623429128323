import React from 'react';

import { TimedComponentProps } from './TimedComponentWrapper.types';

const TimedComponentWrapper = ({ startDate, endDate, children }: TimedComponentProps): JSX.Element | null => {
  const todayTimeStamp = Date.now();

  const shouldDisplay = todayTimeStamp > new Date(startDate).getTime() && new Date(endDate).getTime() > todayTimeStamp;

  if (shouldDisplay) return <>{children}</>;
  else return null;
};

export default TimedComponentWrapper;
