import React from 'react';

import { Modal } from 'components/UI';
import { SolidMigrationModalProps } from './SolidMigrationModal.types';
import Button from 'components/Button';
import useSolidMigrationModal from './hooks/UseSolidMigrationModal';

const SolidMigrationModal = ({ shouldShowSolidModal }: SolidMigrationModalProps) => {
  const { show, onClose, onNavigateToBankAccounts } = useSolidMigrationModal({ shouldShowSolidModal });

  return (
    <Modal show={show} onClose={onClose} fitContent={true} title="Important Notice">
      <div className="tw-mx-8 tw-my-8 tw-min-w-96">
        <div className="content tw-mb-8 tw-flex tw-flex-col tw-gap-4">
          <b>Upcoming Change to Your Loop US Dollar Operating Account.</b>
          <p>
            As part of our ongoing efforts to enhance our financial technology infrastructure, your Loop USD account
            number has been changed as of XXXXXX.
          </p>
          <p>Any transactions that are currently pending will be processed until XX/XX/2024.</p>
          <div>
            <b className="tw-mb-1 tw-block">What you need to know:</b>
            <ul className="tw-list-disc tw-ml-6">
              <li>
                <strong>New Account Number:</strong> Your current USD account number will be replaced.
              </li>
              <li>
                <strong>Unchanged Routing Number:</strong> Your routing number will remain the same.
              </li>
              <li>
                <strong>Update Your Records:</strong> After the change, please update any recurring transactions (such
                as direct deposits or bill payments) to use your new account number.
              </li>
            </ul>
          </div>

          <p> If you have any questions or need assistance, please contact our support@bankonloop.com. </p>
        </div>

        <Button primary onClick={onNavigateToBankAccounts}>
          View your updated bank details
        </Button>
      </div>
    </Modal>
  );
};

export default SolidMigrationModal;
