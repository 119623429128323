import { CREDIT_ASSESSMENT_STATUS } from 'constants/index';

export enum UserRoles {
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  DIRECTOR = 'DIRECTOR',
  SHAREHOLDER = 'SHAREHOLDER',
  SIGNING_OFFICER = 'SIGNING_OFFICER',
  ACCOUNT_OWNER = 'ACCOUNT_OWNER',
}

export enum InternalContactRole {
  account_owner = 'account_owner',
  admin = 'admin',
  member = 'member',
  bookkeeper = 'bookkeeper',
  read_only = 'read_only',
}

export enum InternalContactStatus {
  active = 'active',
  inactive = 'inactive',
  not_invited = 'not_invited',
  pending_info = 'pending_info',
  pending_verification = 'pending_verification',
}

export type InternalContact = {
  id: string;
  role: InternalContactRole;
  firstName: string;
  lastName: string;
  name: string;
  permissions: string[];
  status: InternalContactStatus;
};

export type TeamMember = {
  id: string;
  role: InternalContactRole;
  firstName: string;
  fullName: string;
  lastName: string;
  initials: string;
  lastActiveAt: string;
  manager: InternalContact | null;
  permissions: string[];
  positions: string[];
  status: InternalContactStatus;
  rules: { paymentApprovalAmount: { amount: number; currency: string } };
  roleValue: string;
};

export type UserPermissions = {
  manageBankAccounts: boolean;
  manageExternalAccounts: boolean;
  manageLinesOfCredit: boolean;
  managePayments: boolean;
};

export type UserSettings = {
  emailNotification: boolean;
  smsNotification: boolean;
};

export type UserPreAuthorizationForm = {
  id: string;
  firstName: string;
  lastName: string;
  signedAt: Date;
  bankAccount: {}; //TODO
  version: string;
};

export type UserAgreement = {
  id: string;
  status: string;
  documentType: string;
  productType: string;
  signedAt?: Date;
  signedBy?: string;
  url?: string;
  name?: string;
};

export type UserAddress = {
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  countrySubdivision?: string;
  unitNumber?: string;
  category?: string;
};

export type Account = {
  name: string;
  subscription: {
    paid: boolean;
  };
  features: string[];
  hasCadCoreIntegration: boolean;
  cadAccountFeatureDisabled: boolean;
  // TODO add Account type fields
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type User = {
  id: string;
  account: Account | null;
  accounts: { id: string; name: string }[];
  activeAccountId?: string;
  address: UserAddress;
  agreements: UserAgreement[];
  birthdate: Date;
  creditAgreementSigned: boolean;
  email: string;
  embossedName?: string;
  enabled: boolean;
  externalAccountsConnected?: boolean;
  creditAssessment: {
    status: typeof CREDIT_ASSESSMENT_STATUS;
  };
  firstName?: string;
  internalContact: InternalContact | null;
  lastName?: string;
  lastActiveAt?: Date;
  mobilePhoneNumber?: string;
  mobilePhoneNumberVerified: boolean;
  permissions: UserPermissions;
  phoneNumber?: string;
  preAuthorizationForms: UserPreAuthorizationForm;
  roles: UserRoles[];
  settings: UserSettings;
  smsMfaEnabled: boolean;
  socialInsuranceNumber?: string;
  superAdmin: boolean;
  suggestedCreditCardDisplayNames?: string[];
  totpMfaEnabled: boolean;
  __typename: UserTypes;
};

export enum UserTypes {
  REGULAR_USER = 'User',
  PAYOR_USER = 'PayorUser',
}
