import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SolidMigrationModalProps } from '../SolidMigrationModal.types';

const useSolidMigrationModal = ({ shouldShowSolidModal }: SolidMigrationModalProps) => {
  const [show, setShow] = useState(shouldShowSolidModal);
  const history = useHistory();

  const onNavigateToBankAccounts = () => {
    setShow(false);
    history.push('/dashboard/accounts');
  };

  const onClose = () => {
    setShow(false);
  };

  return {
    onNavigateToBankAccounts,
    show,
    onClose,
  };
};

export default useSolidMigrationModal;
